import { Thermostat } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/thermostats';

const initialState = {
  thermostatsById: {},
  thermostatDisplayNames: {},
};

export type Thermostats = {
  thermostatsById: ThermostatsById;
  thermostatDisplayNames: ThermostatDisplayNames;
};

export type ThermostatsById = {
  [id: string]: Thermostat;
};

export type ThermostatDisplayNames = {
  [id: string]: string;
};

// TODO: need more detailed normalizing
const normalizeThermostat = (data) => ({
  ...data,
  venstarThermostatStatus: data.venstarThermostatStatus
    ? {
        ...data.venstarThermostatStatus,
        additionalTemperatureReadingsList: isDefined(
          data.venstarThermostatStatus?.additionalTemperatureReadingsList
        )
          ? JSON.parse(
              data.venstarThermostatStatus?.additionalTemperatureReadingsList
            )
          : null,
      }
    : null,
  venstarThermostatModel: data.venstarThermostatModel
    ? `Venstar ${data.venstarThermostatModel}`
    : undefined,
});

const Thermostats = (state: Thermostats = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_THERMOSTAT_BY_ID_SUCCESS:
      return pipe(
        assocPath(
          ['thermostatsById', action.id],
          normalizeThermostat(action.payload)
        )
      )(state);

    case Actions.GET_THERMOSTAT_DISPLAY_NAME_SUCCESS:
      return pipe(
        assocPath(
          ['thermostatDisplayNames', action.id],
          action.payload.displayName
        )
      )(state);

    case Actions.GET_THERMOSTAT_BY_ID_ERROR:
    case Actions.GET_THERMOSTAT_DETAIL_BY_ID_ERROR:
    case Actions.GET_THERMOSTAT_DISPLAY_NAME_ERROR:
      return state;

    default:
      return state;
  }
};

export default Thermostats;
